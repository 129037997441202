// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Portal-Majic-primary: mat-palette($mat-indigo);
$Portal-Majic-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$Portal-Majic-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$Portal-Majic-theme: mat-light-theme($Portal-Majic-primary, $Portal-Majic-accent, $Portal-Majic-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($Portal-Majic-theme);
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "core/variables";
@import "core/mixins";
@import "bootstrap/scss/bootstrap";
// Core Components
@import "core/buttons";
@import "core/checkboxes";
@import "core/radios";
@import "core/forms";
@import "core/input-group";
@import "core/images";
@import "core/navbar";
@import "core/alerts";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/popover";
@import "core/dropdown";
@import "core/togglebutton";
@import "core/ripples";
@import "core/footers";
@import "core/sidebar-and-main-panel";
@import "core/fixed-plugin";
@import "core/tables";
@import "core/misc";
@import "core/cards";
@import "core/cards/card-stats";
@import "core/cards/card-profile";
@import "core/cards/card-plain";
//plugin scss
@import "core/plugins/animate";
@import "core/plugins/chartist";
@import "core/plugins/perfect-scrollbar";
@import "core/responsive";
@import "core/angular-modal.scss";